<template>
    <div class="test-app-branding-color-scheme">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div>colors: {{ colors }}</div>
                    <div>original: {{ original }}</div>

                    <app-branding-color-scheme
                        v-model="colors"
                        :states="states"
                        :original="original"

                        :title="'Test colors'"
                    ></app-branding-color-scheme>

                    <!-- <app-branding-color-scheme
                        v-model="colors"
                        :states="states"

                        :title="'Test colors'"
                    ></app-branding-color-scheme> -->

                    <!-- <app-branding-color-scheme
                        v-model="colors"
                        :states="states"

                        :title="'Test colors'"
                    ></app-branding-color-scheme> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import appBrandingColorScheme from '@/components/app-branding-color-scheme'

export default {
    components: {
        appBrandingColorScheme,
    },

    data() {
        return {
            // colors:   ['#ff0000', '#00ff00', '#0000ff'],
            // original: ['#ff0000', '#00ff00', '#0000ff'],

            colors:   ["#fdd52533", "#9e8000", "#fff7d3"],
            original: ["#fdd52533", "#9e8000", "#fff7d3"],

            // colors:   ["#e60000", "#990000", "#f15959"],
            // original: ["#e60000", "#990000", "#f15959"],

            states: {
                default: true,
                // hover: true,
                // active: true
            },
        }
    },
}
</script>

<style lang="scss">

</style>